<template>
  <div class="unload">
    <r-main-panel>
      <unload-right-panel />
    </r-main-panel>
    <unload-content />
  </div>
</template>

<script>
export default {
  components: {
    unloadRightPanel: () => import('./components/unload-list'),
    unloadContent: () => import('./components/unload-content')
  }
}
</script>

<style lang="scss" scoped>
.unload {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>
